// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-24.use[1]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-24.use[2]!../../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-24.use[3]!../css/icons.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Poppins/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Poppins/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Poppins/Poppins-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/bank-icons.eot?cq7cqi", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/bank-icons.ttf?cq7cqi", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/bank-icons.woff?cq7cqi", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/bank-icons.svg?cq7cqi", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___, { hash: "#bank-icons" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Font Poppins */
@font-face {
  font-family: 'Poppins Medium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Poppins Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Poppins Light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: 200;
  font-style: normal; }

/* ICONOGRAFIA */
@font-face {
  font-family: 'bank-icons';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("embedded-opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype"), url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bank-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-baixar:before {
  content: "\\e900";
  color: var(--color-primary); }

.icon-integration:before {
  content: "\\e901"; }

.icon-information:before {
  content: "\\e902"; }

.icon-go-back-arrow:before {
  content: "\\e903";
  color: #fff; }

.icon-launched:before {
  content: "\\e904"; }

.icon-document-analysis:before {
  content: "\\e905"; }

.icon-next:before {
  content: "\\e906"; }

.icon-force-payment:before {
  content: "\\e907"; }

.icon-use-avatar .path1:before {
  content: "\\e908";
  color: #d6d9e2; }

.icon-use-avatar .path2:before {
  content: "\\e909";
  margin-left: -1em;
  color: #eff2fa; }

.icon-avatar:before {
  content: "\\e90a"; }

.icon-listing-option:before {
  content: "\\e90b"; }

.icon-grid:before {
  content: "\\e90c"; }

.icon-attach:before {
  content: "\\e90d"; }

.icon-sort:before {
  content: "\\e90e"; }

.icon-attention:before {
  content: "\\e90f"; }

.icon-zoom-out:before {
  content: "\\e910"; }

.icon-download:before {
  content: "\\e911"; }

.icon-zoom-in:before {
  content: "\\e912"; }

.icon-financial:before {
  content: "\\e913";
  color: var(--color-primary); }

.icon-doc-complementation:before {
  content: "\\e914";
  color: var(--color-primary); }

.icon-ocr:before {
  content: "\\e915";
  color: var(--color-primary); }

.icon-document-list:before {
  content: "\\e916";
  color: var(--color-primary); }

.icon-links:before {
  content: "\\e917";
  color: var(--color-primary); }

.icon-erp-sent:before {
  content: "\\e918";
  color: var(--color-primary); }

.icon-accounting:before {
  content: "\\e919";
  color: var(--color-primary); }

.icon-pdf:before {
  content: "\\e91a"; }

.icon-regras-apuracao:before {
  content: "\\e91b"; }

.icon-tax:before {
  content: "\\e91c"; }

.icon-copy-clean:before {
  content: "\\e91d"; }

.icon-brain:before {
  content: "\\e91e"; }

.icon-edit-file-clean:before {
  content: "\\e91f"; }

.icon-down-arrow:before {
  content: "\\e920"; }

.icon-analytics:before {
  content: "\\e921"; }

.icon-online-payment:before {
  content: "\\e922"; }

.icon-book:before {
  content: "\\e923"; }

.icon-circle:before {
  content: "\\e924"; }

.icon-login:before {
  content: "\\e925"; }

.icon-folder:before {
  content: "\\e926"; }

.icon-correct-check:before {
  content: "\\e927";
  color: #25a57d; }

.icon-cloud-computing:before {
  content: "\\e928"; }

.icon-less:before {
  content: "\\e929"; }

.icon-invoice:before {
  content: "\\e92a";
  color: var(--color-primary); }

.icon-add-file:before {
  content: "\\e92b"; }

.icon-circle-arrow-right:before {
  content: "\\e92c";
  color: var(--color-primary); }

.icon-team-collaborate:before {
  content: "\\e92d"; }

.icon-team:before {
  content: "\\e92e"; }

.icon-user:before {
  content: "\\e92f"; }

.icon-idea:before {
  content: "\\e930"; }

.icon-calculator:before {
  content: "\\e931"; }

.icon-calendar:before {
  content: "\\e932"; }

.icon-calculator-display:before {
  content: "\\e933"; }

.icon-privacy:before {
  content: "\\e934"; }

.icon-add:before {
  content: "\\e935"; }

.icon-email:before {
  content: "\\e936";
  color: var(--color-primary); }

.icon-job:before {
  content: "\\e937"; }

.icon-dollar:before {
  content: "\\e938"; }

.icon-circle-arrow-left:before {
  content: "\\e939"; }

.icon-file-correct:before {
  content: "\\e93a"; }

.icon-expand:before {
  content: "\\e93b"; }

.icon-real-estate:before {
  content: "\\e93c"; }

.icon-cash:before {
  content: "\\e93d"; }

.icon-money:before {
  content: "\\e93e"; }

.icon-contract:before {
  content: "\\e93f"; }

.icon-email-open:before {
  content: "\\e940";
  color: var(--color-primary); }

.icon-performance:before {
  content: "\\e941"; }

.icon-login-key:before {
  content: "\\e942"; }

.icon-fluctuation:before {
  content: "\\e943"; }

.icon-exit:before {
  content: "\\e944"; }

.icon-pointing-up:before {
  content: "\\e945"; }

.icon-key:before {
  content: "\\e946"; }

.icon-growth:before {
  content: "\\e947"; }

.icon-copy:before {
  content: "\\e948";
  color: #fff; }

.icon-file-written:before {
  content: "\\e949"; }

.icon-mobile:before {
  content: "\\e94a"; }

.icon-team-work:before {
  content: "\\e94b"; }

.icon-headset:before {
  content: "\\e94c"; }

.icon-contact:before {
  content: "\\e94d"; }

.icon-list:before {
  content: "\\e94e"; }

.icon-bar-code:before {
  content: "\\e94f"; }

.icon-folded-invoice:before {
  content: "\\e950"; }

.icon-box-plus:before {
  content: "\\e951"; }

.icon-phone-call:before {
  content: "\\e952"; }

.icon-nav-collapse:before {
  content: "\\e953"; }

.icon-rocket:before {
  content: "\\e954"; }

.icon-writing:before {
  content: "\\e955"; }

.icon-bars-graphic:before {
  content: "\\e956"; }

.icon-info:before {
  content: "\\e957"; }

.icon-file:before {
  content: "\\e958"; }

.icon-edit:before {
  content: "\\e959"; }

.icon-settings:before {
  content: "\\e95a"; }

.icon-trust:before {
  content: "\\e95b"; }

.icon-credit-card:before {
  content: "\\e95c";
  color: var(--color-primary); }

.icon-pointing-left:before {
  content: "\\e95d"; }

.icon-point-of-service:before {
  content: "\\e95e"; }

.icon-graph:before {
  content: "\\e95f"; }

.icon-wrong-check:before {
  content: "\\e960";
  color: #d54545; }

.icon-billet:before {
  content: "\\e961"; }

.icon-trash:before {
  content: "\\e962"; }

.icon-document-more:before {
  content: "\\e963"; }

.icon-audit:before {
  content: "\\e964"; }

.icon-counterclockwise:before {
  content: "\\e965"; }

.icon-contract-edit:before {
  content: "\\e966"; }

.icon-box-less:before {
  content: "\\e967"; }

.icon-right-arrow:before {
  content: "\\e968"; }

.icon-blockchain:before {
  content: "\\e969";
  color: var(--color-primary); }

.icon-password:before {
  content: "\\e96a"; }

.icon-magnifier-tool:before {
  content: "\\e96b"; }

.icon-newspaper:before {
  content: "\\e96c"; }

.icon-more-options:before {
  content: "\\e96d"; }

.icon-loadding-clock:before {
  content: "\\e96e"; }

.icon-smartphone:before {
  content: "\\e96f"; }

.icon-chat-out:before {
  content: "\\e970"; }

.icon-eye:before {
  content: "\\e971"; }

.icon-protection:before {
  content: "\\e972"; }

.icon-group:before {
  content: "\\e973"; }

.icon-percent:before {
  content: "\\e974"; }

.icon-responsive:before {
  content: "\\e975"; }

.icon-folder-open:before {
  content: "\\e976"; }

.icon-target:before {
  content: "\\e977"; }

.icon-focus:before {
  content: "\\e978"; }

.icon-alarm-clock:before {
  content: "\\e979"; }

.icon-plus:before {
  content: "\\e97a"; }

.icon-paper:before {
  content: "\\e97b"; }

.icon-left-arrow:before {
  content: "\\e97c"; }

.icon-bitcoin:before {
  content: "\\e97d"; }

.icon-send:before {
  content: "\\e97e"; }

.icon-edit-file:before {
  content: "\\e97f"; }

.icon-financial-statement:before {
  content: "\\e980"; }

.icon-refresh:before {
  content: "\\e981"; }

.icon-link:before {
  content: "\\e982"; }

.icon-cloud:before {
  content: "\\e983"; }

.icon-chat:before {
  content: "\\e984"; }

.icon-tax:before {
  content: "\\e985"; }

.icon-add-group:before {
  content: "\\e986"; }

.icon-hide:before {
  content: "\\e987"; }

.icon-puzzle:before {
  content: "\\e988"; }

.icon-wallet:before {
  content: "\\e989"; }

.icon-file-1:before {
  content: "\\e98a"; }

.icon-config:before {
  content: "\\e98b";
  color: #d6d9e2; }

.icon-file-clean:before {
  content: "\\e98c";
  margin-left: -1em;
  color: #eff2fa; }

.icon-file-eye:before {
  content: "\\e98d"; }

.icon-beach:before {
  content: "\\e98e"; }

.icon-id-card:before {
  content: "\\e98f"; }

.icon-printer:before {
  content: "\\e990"; }

.icon-remove-user:before {
  content: "\\e991"; }

:root {
  --color-primary:        #E2B874;
  --color-primary-hover:  #E2B874;
  --color-secondary:      #152E3E;
  --color-secondary-hover:#152E3E; }

body, html {
  scroll-behavior: smooth;
  background: #f4f5f7;
  font-size: 100%;
  width: 100%;
  min-height: 100%; }

body {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 0.875rem;
  transition: all .3s ease; }

hr {
  border-top: 1px solid #DFE3E6; }

[class^='icon-r-'], [class*=' icon-r-'] {
  width: 14px;
  height: 14px;
  background-color: rgba(0, 0, 0, 0.82); }

/* PRIMEVUE */
/* Placeholder */
::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.42px;
  font-size: 0.875rem; }

::-moz-placeholder {
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.42px;
  font-size: 0.875rem; }

:-moz-placeholder {
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.42px;
  font-size: 0.875rem; }

:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.42px;
  font-size: 0.875rem; }

/* body::-webkit-scrollbar {
    width: .75rem;
}
*::-webkit-scrollbar-track {
    background-color: red;
}
*::-webkit-scrollbar-thumb {
    background-color: green;
    border-radius: 9999px;
} */
/* Selection */
::-moz-selection {
  background: rgba(var(--color-primary), 0.6);
  color: rgba(0, 0, 0, 0.87); }
::selection {
  background: rgba(var(--color-primary), 0.6);
  color: rgba(0, 0, 0, 0.87); }

::-moz-selection {
  background: rgba(var(--color-primary), 0.6);
  color: rgba(0, 0, 0, 0.87); }

/* Label */
label {
  font-family: "Roboto", "Arial", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #00000089;
  font-size: 0.75rem;
  margin-bottom: 0; }

.cursor-not-allowed {
  cursor: not-allowed; }

.cursor-pointer {
  cursor: pointer; }

.p-field label {
  margin-bottom: 0.1875rem; }

/* Inputs */
input:-webkit-autofill {
  box-shadow: 0 0 0 30px #FFFFFF inset;
  -webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
  border-radius: 30%; }

.p-inputtext {
  font-family: "Roboto-Regular", "Arial", sans-serif;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0px;
  border: 1px solid #DFE3E6;
  border-radius: 6px; }
  .p-inputtext:enabled:hover {
    border-color: var(--color-primary); }
  .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: var(--color-primary); }
  .p-inputtext:not(.p-inputtextarea) {
    height: 40px; }

span.p-calendar-w-btn .p-inputtext {
  border-right-width: 0; }

span.p-calendar-w-btn:hover input.p-inputtext {
  border-color: var(--color-primary); }

span.p-calendar-w-btn:hover button.p-datepicker-trigger {
  border-color: var(--color-primary); }

.p-inputnumber .p-button {
  height: initial; }

/* Chips */
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: var(--color-primary); }

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: var(--color-primary); }

.p-chips .p-chips-multiple-container .p-chips-token {
  background: var(--color-secondary);
  padding: 0.20rem 0.5rem;
  font-size: 14px;
  font-family: "Roboto", "Arial", sans-serif;
  color: #fff; }

/* Dropdown */
.p-dropdown {
  border: 1px solid #DFE3E6;
  border-radius: 6px;
  height: 40px; }
  .p-dropdown:not(.p-disabled):hover {
    border-color: #DFE3E6; }
  .p-dropdown:not(.p-disabled).p-focus {
    border-color: var(--color-primary);
    box-shadow: none; }
  .p-dropdown.input-alert {
    border-color: var(--color-primary) !important; }
  .p-dropdown:hover .p-dropdown-clear-icon {
    display: block; }
  .p-dropdown .p-dropdown-trigger-icon {
    font-size: 0.875rem;
    color: #C0C4CC; }
  .p-dropdown .p-dropdown-clear-icon {
    right: 30px;
    display: none;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #DFE3E6;
    font-size: 8px;
    padding: 4px;
    margin-right: 8px; }

.p-dropdown-panel {
  box-shadow: 0px 4px 4px #DFE3E6;
  border: 1px solid #DFE3E6; }
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto-Regular", "Arial", sans-serif;
    font-size: 0.8125rem;
    letter-spacing: 0px; }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      color: var(--color-primary);
      font-size: 0.875rem;
      background: rgba(var(--color-primary), 0.85);
      font-family: "Roboto-Medium", "Arial", sans-serif; }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      background: rgba(var(--color-secondary), 0.9); }

.p-inputtext {
  border-radius: 6px;
  border: 1px solid #DFE3E6; }
  .p-inputtext.p-chips-multiple-container {
    border-radius: 6px;
    border: 1px solid #DFE3E6; }
  .p-inputtext.input-alert {
    border-color: var(--color-primary); }

.p-autocomplete.p-invalid {
  display: flex; }

.p-autocomplete .p-button-icon {
  color: #ced4da;
  font-size: 0.875rem; }

/* Modal Delete File */
.alert-exclusao button.el-button.el-button--default.el-button--small.el-button--primary {
  background-color: #D54545 !important;
  border: solid 1px #D54545 !important; }

.alert-exclusao button.el-button.el-button--default.el-button--small.el-button--primary:hover {
  background-color: #D54545 !important;
  border: solid 1px #D54545 !important;
  color: white !important; }

.alert-exclusao button.el-button.el-button--default.el-button--small:hover {
  background-color: white !important;
  border-color: #DCDFE6 !important;
  color: #606266 !important; }

/* Checkbox */
.p-checkbox .p-checkbox-box {
  border: 1px solid #DFE3E6;
  border-radius: 4px; }
  .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
    border-color: var(--color-primary); }
  .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
    border-color: var(--color-primary);
    box-shadow: none; }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: var(--color-primary);
    background: var(--color-primary); }
    .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
      border-color: var(--color-primary);
      background: var(--color-primary);
      color: #ffffff; }

/* Radio */
.p-radiobutton .p-radiobutton-box {
  border: 1px solid #DFE3E6; }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: var(--color-primary); }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: var(--color-primary); }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: var(--color-primary);
    background: var(--color-primary); }
    .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
      border-color: var(--color-primary);
      background: var(--color-primary);
      color: #ffffff; }

/* Buttons */
.p-button {
  background: var(--color-primary);
  border-radius: 6px;
  border: solid var(--color-primary) 1px;
  height: 2.5rem;
  font-family: "Roboto-Regular", "Arial", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0px;
  padding: 0.75rem 1.875rem;
  box-shadow: 0px 3px 2px #D1D1D1; }
  .p-button .p-button-icon {
    background-color: #FFFFFF; }
    .p-button .p-button-icon.pi {
      background-color: transparent !important; }
  .p-button:hover {
    box-shadow: none; }
  .p-button.p-button-close {
    height: 26px;
    border-radius: 4px; }
  .p-button.p-autocomplete-dropdown {
    box-shadow: none;
    background: #FFFFFF;
    color: #808D96; }
  .p-button.p-datepicker-trigger {
    box-shadow: none;
    border: solid #DFE3E6 1px;
    border-left-width: 0;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.54); }
    .p-button.p-datepicker-trigger:enabled:hover {
      color: rgba(0, 0, 0, 0.54);
      background-color: #fff;
      border-left-width: 0; }
  .p-button:enabled:hover, .p-button:enabled:active {
    background-color: var(--color-primary-hover);
    border: solid var(--color-primary-hover) 1px;
    box-shadow: none; }
  .p-button:focus {
    box-shadow: 0px 3px 2px #D1D1D1; }
  .p-button.transparent {
    background-color: #FFFFFF33;
    border: 0; }
    .p-button.transparent:enabled:hover {
      background-color: #FFFFFF66;
      border: 0; }
  .p-button.primary.disabled {
    background-color: transparent;
    border-radius: 50%;
    color: var(--color-secondary);
    opacity: 1;
    border: none;
    box-shadow: none; }
    .p-button.primary.disabled:hover {
      border-radius: 50%;
      background-color: #DFE3E6;
      color: var(--color-secondary);
      opacity: 1;
      border: none;
      box-shadow: none; }
      .p-button.primary.disabled:hover .p-button-icon {
        background-color: var(--color-secondary); }
    .p-button.primary.disabled .p-button-icon {
      background-color: rgba(0, 0, 0, 0.54); }
  .p-button.outlined {
    background: #fff;
    color: var(--color-primary); }
    .p-button.outlined:enabled:hover {
      color: var(--color-primary);
      background-color: #FCF8F0;
      border: solid var(--color-primary-hover) 1px; }
    .p-button.outlined .p-button-icon {
      background-color: var(--color-primary); }
  .p-button.secondary {
    background: var(--color-secondary);
    border-color: var(--color-secondary); }
    .p-button.secondary .icon-r-audit {
      background-color: var(--color-secondary); }
    .p-button.secondary:enabled:hover {
      background-color: var(--color-secondary);
      border: solid var(--color-secondary) 1px; }
    .p-button.secondary:disabled {
      background-color: #EAEBEB;
      color: #152E3E54;
      opacity: 1;
      border: none;
      box-shadow: none; }
    .p-button.secondary.outlined {
      background: #fff;
      color: var(--color-secondary); }
      .p-button.secondary.outlined:enabled:hover {
        color: var(--color-secondary);
        background-color: #E7F2F7;
        border: solid var(--color-secondary) 1px; }
  .p-button.danger {
    background: #DA4040;
    border-color: #DA4040; }
    .p-button.danger:enabled:hover {
      background-color: #EF5C5C;
      border: solid #EF5C5C 1px; }
    .p-button.danger:disabled {
      background-color: #EAEBEB;
      color: #152E3E54;
      opacity: 1;
      border: none;
      box-shadow: none; }
    .p-button.danger.outlined {
      background: #fff;
      color: #EF5C5C; }
      .p-button.danger.outlined:enabled:hover {
        color: #EF5C5C;
        background-color: #FFEDED;
        border: solid #EF5C5C 1px; }
      .p-button.danger.outlined .p-button-icon {
        background-color: #EF5C5C; }
  .p-button.success {
    background: #25A57D;
    border-color: #25A57D; }
    .p-button.success:enabled:hover {
      color: #ffffff;
      background-color: #40BB94;
      border: solid #25A57D 1px; }
    .p-button.success:disabled {
      background-color: #EAEBEB;
      color: #152E3E54;
      opacity: 1;
      border: none;
      box-shadow: none; }
    .p-button.success.outlined {
      background: #fff;
      color: #25A57D; }
      .p-button.success.outlined:enabled:hover {
        color: #ffffff;
        background-color: #40BB94;
        border: solid #25A57D 1px; }
  .p-button.secondary-small {
    height: 25px;
    background: #FFFFFF;
    border-color: #152E3E;
    color: #152E3E;
    font-size: 0.75rem;
    box-shadow: none; }
    .p-button.secondary-small:hover {
      background: #152E3E;
      color: #FFFFFF;
      border-color: #152E3E; }
      .p-button.secondary-small:hover .icon-r-link {
        background-color: #FFFFFF; }
    .p-button.secondary-small:disabled:hover {
      background-color: #EAEBEB;
      color: #FFFFFF;
      border-color: #EAEBEB; }
      .p-button.secondary-small:disabled:hover .icon-r-link {
        background-color: #FFFFFF; }

/* Switch */
.el-switch .el-switch__label.is-active {
  color: var(--color-primary); }

.el-switch.is-checked .el-switch__core {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important; }

/* Disabled (buttons, inputs, dropdowns) */
.p-disabled, .p-component:disabled {
  background-color: #EFEFEF;
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.33) !important;
  opacity: 1;
  border: #efefef 1px solid;
  box-shadow: none; }
  .p-disabled .p-inputtext, .p-component:disabled .p-inputtext {
    color: rgba(0, 0, 0, 0.33);
    cursor: not-allowed !important; }

.el-select.is-disabled input, .el-input.is-disabled input {
  background-color: #EFEFEF !important;
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.33) !important;
  border: #ced4da 1px solid !important;
  box-shadow: none; }

/* Message */
.p-message {
  border-radius: 0.3125rem; }
  .p-message .p-message-icon {
    display: none; }
  .p-message.p-message-error {
    border-width: 2px 2px 2px 2px;
    background: #F8D7DA;
    color: #B83C30;
    padding: 1rem 0.7rem;
    border: solid #F5C6CB; }
    .p-message.p-message-error i {
      color: #B83C30; }
  .p-message.p-message-success {
    border-width: 2px 2px 2px 2px;
    background: #D4EDDA;
    color: #169A4E;
    padding: 1rem 0.7rem;
    border: solid #C3E6CB; }
    .p-message.p-message-success i {
      color: #169A4E; }
  .p-message.p-message-warn {
    background: #FFF3CD;
    color: #9F8600;
    padding: 1rem 0.7rem;
    border: 2px solid #FFEEBA;
    border-radius: 6px; }
    .p-message.p-message-warn i {
      color: #9F8600; }
  .p-message.p-message-info {
    border-width: 2px 2px 2px 2px;
    background: #CCE5FF;
    color: #1469C4;
    padding: 1rem 0.7rem;
    border: solid #B8DAFF; }
    .p-message.p-message-info i {
      color: #1469C4; }
  .p-message .p-message-wrapper {
    padding: 0; }
  .p-message .p-message-text {
    font-family: "Roboto-Regular", "Arial", sans-serif;
    font-size: 1rem;
    letter-spacing: 0.48px; }

/* Tooltip */
.p-tooltip {
  word-break: break-all;
  z-index: 10000 !important; }
  .p-tooltip .p-tooltip-text {
    background: var(--color-secondary);
    border-radius: 5px;
    font-family: "Roboto-Regular", "Arial", sans-serif;
    font-size: 0.875rem;
    letter-spacing: 0.36px; }
  .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: var(--color-secondary); }
  .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: var(--color-secondary); }
  .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: var(--color-secondary); }
  .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: var(--color-secondary); }

/* Fieldset */
.p-fieldset {
  border: 1px solid #AAAAAA;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto-Regular", "Arial", sans-serif;
  font-size: 0.875rem;
  border-radius: 8px;
  padding: 10px; }
  .p-fieldset .p-fieldset-legend {
    padding: 0;
    border: 1px solid transparent;
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 1rem;
    color: #152E3E;
    letter-spacing: 0px;
    background: transparent;
    border-radius: 3px;
    width: auto;
    margin-left: 10px; }

/* Panel */
.p-panel .p-panel-header {
  position: relative;
  border: 1px solid #DFE3E6;
  border-bottom: none;
  padding: 1rem 1.5rem;
  background: #fff;
  font-family: "Roboto", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  letter-spacing: 0px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px; }
  .p-panel .p-panel-header::before {
    content: "";
    position: absolute;
    height: 1px;
    background: #DFE3E6;
    bottom: 0;
    left: 1.5rem;
    right: 1.5rem; }

.p-panel .p-panel-content {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px; }

/* Accordion */
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid #DFE3E6;
  color: var(--color-secondary);
  background: #fff;
  font-weight: 600;
  border-radius: 3px;
  transition: box-shadow 0.2s; }
  .p-accordion .p-accordion-header .p-accordion-header-link:hover, .p-accordion .p-accordion-header .p-accordion-header-link:focus, .p-accordion .p-accordion-header .p-accordion-header-link:active {
    background-color: #f6f6f6 !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: #DFE3E6 !important;
    text-decoration: none; }
  .p-accordion .p-accordion-header .p-accordion-header-link.p-highlight {
    background-color: #f6f6f6 !important;
    outline: none;
    border-color: #DFE3E6 !important; }

.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #DFE3E6;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto-Regular", "Arial", sans-serif;
  font-size: 0.875rem;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

/* Error Message */
span.p-invalid {
  display: block;
  color: #C11010;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  font-family: "Roboto-Bold", "Arial", sans-serif; }

span.p-warning {
  display: block;
  color: #9F8600;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 14px;
  font-family: "Roboto-Bold", "Arial", sans-serif; }

.icon-r-circle-warning-filled {
  background-color: #E2C43D !important; }

.icon-r-trash {
  background-color: #D54545; }

/* Tabview */
.el-tabs {
  padding: 0 20px 20px;
  border: solid 1px #DFE3E6;
  border-radius: 10px; }
  .el-tabs .el-tabs__item {
    height: 64px;
    padding: 10px 20px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54); }
    .el-tabs .el-tabs__item.is-active {
      color: var(--color-primary); }
      .el-tabs .el-tabs__item.is-active:hover {
        color: var(--color-primary); }
    .el-tabs .el-tabs__item:hover {
      color: var(--color-secondary); }
  .el-tabs .el-tabs__active-bar {
    background-color: var(--color-primary); }
  .el-tabs .el-badge__content.is-fixed {
    top: 7px;
    right: 14px; }

.p-tabview {
  background: #fff;
  border-radius: 10px;
  border: solid 1px #DFE3E6; }
  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: 1px solid #DFE3E6;
    border-width: 0 0 2px 0;
    margin-left: 1rem;
    margin-right: 1rem; }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      color: rgba(0, 0, 0, 0.54);
      font-family: "Roboto-Medium", "Arial", sans-serif;
      font-size: 1rem;
      letter-spacing: 0px; }
      .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: none; }
    .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
      background: #ffffff;
      border-color: var(--color-secondary);
      color: var(--color-secondary);
      text-decoration: none; }
    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background: #ffffff;
      border-color: var(--color-primary);
      color: var(--color-primary);
      text-decoration: none; }
  .p-tabview .p-tabview-panels {
    background: #ffffff;
    padding: 1rem;
    border: 0 none;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    font-family: "Roboto-Regular", "Arial", sans-serif;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px; }

/* Tab */
.p-tabmenu .p-tabmenu-nav {
  font: normal normal medium 16px/24px "Roboto-Medium", "Arial", sans-serif;
  letter-spacing: 0px; }

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: var(--color-primary);
  color: var(--color-primary); }

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  border-color: rgba(0, 0, 0, 0.54);
  color: rgba(0, 0, 0, 0.54); }

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none; }

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  color: rgba(0, 0, 0, 0.54); }

/* Steps */
.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 3px;
  background: transparent; }

/* Table */
.p-datatable .p-datatable-tbody > tr {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.87);
  outline-color: transparent; }

.p-datatable .p-datatable-thead > tr > th {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0px;
  color: rgba(0, 0, 0, 0.54);
  background-color: #FFFFFF; }

.p-datatable .p-sortable-column {
  outline-color: rgba(var(--color-primary), 0.7); }
  .p-datatable .p-sortable-column.p-highlight {
    outline-color: rgba(var(--color-primary), 0.7);
    color: rgba(0, 0, 0, 0.82); }
    .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: rgba(0, 0, 0, 0.82); }
  .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: rgba(0, 0, 0, 0.33); }
  .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background: #fff;
    color: rgba(0, 0, 0, 0.82); }
    .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: rgba(0, 0, 0, 0.82); }

/* Modal */
.p-dialog {
  border-radius: 8px;
  padding: 0 1.5rem;
  background-color: #ffffff; }
  .p-dialog.p-dialog-maximized {
    border-radius: 0; }
  .p-dialog .p-dialog-header {
    border-bottom: 1px solid #DFE3E6;
    background: #ffffff;
    padding: 1.25rem 0rem; }
    .p-dialog .p-dialog-header .icon-r-circle-warning-filled {
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      vertical-align: middle; }
    .p-dialog .p-dialog-header .p-dialog-header-icons {
      background-color: transparent; }
      .p-dialog .p-dialog-header .p-dialog-header-icons .p-dialog-header-icon {
        width: 1.5rem;
        height: 1.5rem;
        color: #707e8a;
        border: 0 none;
        background: #dddfe5;
        border-radius: 50%;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        margin-right: 0.5rem; }
        .p-dialog .p-dialog-header .p-dialog-header-icons .p-dialog-header-icon.p-dialog-header-maximize span {
          font-size: 0.8rem; }
        .p-dialog .p-dialog-header .p-dialog-header-icons .p-dialog-header-icon .pi-times:before {
          content: "\\e90b";
          color: #707e8a;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center; }
  .p-dialog h3 {
    color: var(--color-secondary);
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 1.25rem;
    font-weight: bold;
    font-style: normal;
    margin-bottom: 0; }
  .p-dialog .p-dialog-content {
    padding: 1.25rem 1.5rem;
    margin: 0 -1.5rem;
    border-radius: 8px; }
  .p-dialog .p-dialog-footer {
    border-top: 1px solid #DFE3E6;
    padding: 1.25rem 0rem; }

/* Calendar */
.p-datepicker table td > span {
  width: 1.5rem;
  height: 1.5rem; }

.p-datepicker .p-datepicker-year {
  border: none; }

.p-datepicker .p-datepicker-month.p-highlight {
  background-color: var(--color-primary-hover);
  color: #FFFFFF; }

.p-datepicker .p-highlight {
  background-color: var(--color-primary-hover) !important;
  color: #FFFFFF !important; }

.p-datepicker table th {
  padding: 0.1rem; }

/* Sidebar */
.p-sidebar__header {
  border-bottom: 1px solid #DFE3E6;
  background: #ffffff;
  padding: 1.25rem 0rem; }
  .p-sidebar__header h3 {
    color: var(--color-secondary);
    font-family: "Roboto", "Arial", sans-serif;
    font-size: 1.25rem;
    font-weight: bold;
    font-style: normal;
    margin-bottom: 0; }

/* ELEMENT */
/* Select */
.el-input__inner {
  border-radius: 6px;
  font-family: "Roboto-Regular", "Arial", sans-serif;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0px;
  padding-left: 10px;
  height: 40px; }
  .el-select .el-input__inner:focus {
    border-color: var(--color-primary); }
  .el-input__inner:hover {
    border-color: var(--color-primary); }
  .el-select:hover .el-input__inner {
    border-color: var(--color-primary); }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: var(--color-primary); }

.el-input.is-disabled .el-input__inner {
  background-color: #EFEFEF;
  color: rgba(0, 0, 0, 0.33) !important;
  opacity: 1;
  border: #ced4da 1px solid;
  box-shadow: none; }

.el-select-dropdown {
  box-shadow: 0px 4px 4px #DFE3E6;
  border: solid 1px #DFE3E6; }

.el-select-dropdown.el-popper {
  margin-top: 2px; }
  .el-select-dropdown.el-popper .popper__arrow {
    display: none; }

.el-select-dropdown__item {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto-Regular", "Arial", sans-serif;
  font-size: 0.8125rem;
  letter-spacing: 0px; }
  .el-select-dropdown__item.selected {
    color: var(--color-primary);
    font-size: 0.875rem;
    background: rgba(var(--color-primary), 0.85);
    font-family: "Roboto-Medium", "Arial", sans-serif; }
  .el-select-dropdown__item.hover {
    background: rgba(var(--color-secondary), 0.9); }

/* Popover */
.el-popover {
  background: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  font-family: "Roboto-Medium", "Arial", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.42px;
  color: #FFFFFF;
  border-radius: 5px; }
  .el-popover.popover-filter {
    padding: 0;
    background-color: #FFFFFF;
    border: 1px solid #ebeef5;
    color: var(--color-secondary); }
    .el-popover.popover-filter .back-grey {
      background-color: #F7F7F7;
      border-bottom: 1px solid #E1E5E8;
      margin-bottom: 15px; }
    .el-popover.popover-filter .popper__arrow::after {
      border-bottom-color: #FFFFFF; }

.el-popper .popper__arrow {
  border-top-color: var(--color-secondary); }

.btn-filter-footer {
  border-top: #E1E5E8;
  border-top: 1px solid #E1E5E8;
  margin-top: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none; }
  .btn-filter-footer div:nth-child(1) {
    border-right: 1px solid #E1E5E8;
    color: #737373; }
  .btn-filter-footer div {
    cursor: pointer;
    font-size: 14px;
    padding: 10px 0;
    text-align: center;
    width: 50%;
    color: var(--color-primary); }

.popover-blue {
  padding: 0.3125rem;
  min-width: 40px; }
  .popover-blue span {
    cursor: pointer;
    margin: 0 0.5625rem;
    font-size: 0.75rem; }

/* Tooltip */
.el-tooltip__popper.is-dark {
  background: var(--color-secondary);
  color: #fff;
  border-radius: 5px;
  font-family: "Roboto-Regular", "Arial", sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.36px; }
  .el-tooltip__popper.is-dark[x-placement^=top] .popper__arrow::after {
    border-top-color: var(--color-secondary); }

/* Toast */
.p-toast .p-toast-message {
  border-radius: 8px; }

.p-toast .p-toast-message.p-toast-message-success,
.p-toast .p-toast-message.p-toast-message-info,
.p-toast .p-toast-message.p-toast-message-warn,
.p-toast .p-toast-message.p-toast-message-error {
  border-width: 6px 0 0 0; }

/* Badge (element) */
.el-badge__content--danger {
  background-color: #D54545;
  border-radius: 16px;
  height: initial;
  padding: 2px 9px;
  cursor: pointer; }

/* Tabs */
.el-tabs .el-tabs__active-bar {
  height: 3px;
  border-radius: 4px 4px 0 0; }

/* Loading Spinner */
.loading {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 20; }
  .loading .p-progress-spinner-circle {
    stroke: #E2B874 !important; }

/* Document status */
.document-status-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #DFE3E6;
  border-radius: 8px;
  padding: 0 16px;
  width: 180px;
  height: 40px; }
  .document-status-label__valid {
    margin-right: 30px; }
    .document-status-label__valid:before {
      content: '';
      position: absolute;
      top: 1px;
      left: -8px;
      display: block;
      height: 16px;
      width: 4px;
      border-radius: 0px 3px 3px 0px;
      background-color: #169A4E; }
  .document-status-label__invalid:before {
    content: '';
    position: absolute;
    top: 1px;
    left: -8px;
    display: block;
    height: 16px;
    width: 4px;
    border-radius: 0px 3px 3px 0px;
    background-color: #B83C30; }
  .document-status-label p {
    position: relative;
    margin-bottom: 0;
    font-size: 13px;
    color: #000000;
    font-family: "Roboto", "Arial", sans-serif; }

.status {
  display: block;
  padding: 7px;
  border-radius: 6px;
  text-align: center;
  font-family: "Roboto-Regular", "Arial", sans-serif;
  font-size: 10px; }
  .status--warning {
    color: #9b8302;
    background-color: #faedc5; }
  .status--success {
    color: #1e9057;
    background-color: #c7f7de; }
  .status--danger {
    color: #b83c30;
    background-color: #f8d7da; }

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease; }

.fade-enter,
.fade-leave-active {
  opacity: 0; }

.puff-in-center {
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both; }

@keyframes puff-in-center {
  0% {
    transform: scale(2);
    filter: blur(4px);
    opacity: 0; }
  100% {
    transform: scale(1);
    filter: blur(0px);
    opacity: 1; } }

.swing-out-top-bck {
  animation: swing-out-top-bck 0.45s cubic-bezier(0.6, -0.28, 0.735, 0.045) both; }

@keyframes swing-out-top-bck {
  0% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1; }
  100% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0; } }

.swing-in-top-fwd {
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both; }

@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0; }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1; } }

.slide-in-elliptic-top-fwd {
  animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@keyframes slide-in-elliptic-top-fwd {
  0% {
    transform: translateY(-600px) rotateX(-30deg) scale(0);
    transform-origin: 50% 100%;
    opacity: 0; }
  100% {
    transform: translateY(0) rotateX(0) scale(1);
    transform-origin: 50% 1400px;
    opacity: 1; } }

.scale-in-center {
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

/* ----------------------------------------------
 * Generated by Animista on 2021-1-20 15:8:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */

@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

body {
  background: #fff; }

@media only screen and (max-width: 900px) {
  .image-container {
    display: none; }
  .container-fluid {
    grid-template-columns: 1fr; }
  /* \$form-width: calc(342px - 100px);
  
  \$input-container-width-wrap: 126px; */ }

.home {
  width: 100%;
  height: 100vh;
  min-height: 100vh; }

.container-fluid {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  /* grid-gap: 7rem; */
  /* padding: 0 2rem; */
  padding: 0px;
  background-color: #FFFFFF; }

.image-container {
  display: flex;
  justify-content: flex-start; }

.image-container .banner {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100vh;
  min-height: 700px; }

.image-container .logo {
  position: absolute;
  top: 60px;
  left: 60px;
  height: 48px; }

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.logo-on-mobile-wrap {
  display: none;
  height: 296px;
  position: absolute;
  width: 100%;
  overflow: hidden; }
  .logo-on-mobile-wrap .entry-logo {
    padding-top: 114px;
    position: relative;
    text-align: center;
    z-index: 2; }
  .logo-on-mobile-wrap .bg-mobile {
    position: absolute;
    height: 296px;
    width: 100%;
    top: 0px; }
    .logo-on-mobile-wrap .bg-mobile img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      position: absolute;
      z-index: 1; }

.login-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

.welcome {
  position: absolute;
  top: -50px;
  left: 0px;
  /* padding-bottom: 20px;
  width: \$form-width;
  z-index: 3; */ }

.welcome h3 {
  font-family: "Poppins Light";
  color: #152e3e;
  font-size: 24px;
  margin: 0; }

form {
  position: relative;
  width: 410px;
  /* height: \$form-height; */
  padding: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  z-index: 2;
  border-radius: 4px; }
  form .form-blur {
    position: absolute;
    width: 410px;
    /* height: \$form-blur-height; */
    height: 100%;
    top: -30px;
    left: 0px;
    border-radius: 4px;
    box-shadow: 0px 16px 60px #12293929;
    filter: blur(30px);
    background: #fafafa;
    z-index: -2; }
  form .bg {
    position: absolute;
    width: 410px;
    /* height: \$form-height; */
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 4px;
    background: #FFFFFF;
    z-index: -1; }
  form .icon i {
    height: 18px;
    width: 18px; }

input {
  border: 0;
  color: rgba(0, 0, 0, 0.87);
  outline: none;
  background: none;
  width: 100%; }

input:focus {
  outline: none; }

input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.33); }

input::placeholder {
  color: rgba(0, 0, 0, 0.33); }

.input-container {
  display: grid;
  margin-bottom: 14px;
  /* padding: 5px 0; */
  /* width: 326px; */
  height: 46px;
  border: 1px solid #DFE3E6;
  border-radius: 6px;
  background: #FFFFFF; }

.input-container.user {
  grid-template-columns: 46px 1fr; }

.input-container.password {
  grid-template-columns: 46px 1fr 46px; }

.icon {
  display: flex;
  justify-content: center;
  align-items: center; }

.icon i {
  background-color: rgba(0, 0, 0, 0.33); }

.link-out {
  text-decoration: underline;
  text-underline-position: under;
  color: var(--color-primary);
  letter-spacing: 0px;
  cursor: pointer; }

.font-0-813rem {
  font-size: 0.813rem; }

.inline-items-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.items-to-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.p-field-checkbox label {
  position: relative;
  left: 8px; }

.session-not-forget {
  margin-bottom: 24px; }

button.get-in {
  width: 100%;
  border-radius: 6px;
  min-height: 46px;
  text-align: center;
  letter-spacing: 0px;
  border: 1px solid #e3edf1;
  transition: 0.2s linear all;
  box-shadow: 0px 3px 6px 0px rgba(42, 62, 69, 0.16);
  margin-bottom: 16px;
  font-family: "Poppins Medium", sans-serif;
  font-size: 16px; }
  button.get-in.access {
    background: var(--color-primary) 0% 0% no-repeat padding-box;
    color: #FFFFFF; }
  button.get-in.google {
    background: #FFFFFF;
    color: var(--color-secondary); }
    button.get-in.google img {
      margin-right: 10px; }
  button.get-in.last {
    margin-bottom: 0; }
  button.get-in:hover {
    box-shadow: none; }

.create-account {
  text-align: center;
  margin-top: 10px; }
  .create-account .link-out {
    margin-left: 4px; }

.i18n {
  position: absolute;
  top: 50px;
  right: 50px; }

.group-form-footer {
  display: flex;
  width: 410px;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: center;
  margin-top: 20px;
  color: rgba(var(--color-secondary), 0.33);
  cursor: pointer;
  z-index: 2; }
  .group-form-footer div.lang {
    display: flex;
    align-items: center; }
    .group-form-footer div.lang span {
      font-size: 0.75rem; }
    .group-form-footer div.lang i {
      background-color: rgba(var(--color-secondary), 0.33);
      height: 0.625rem;
      width: 0.625rem;
      margin-left: 4px; }
  .group-form-footer div .link-out:first-child {
    /* margin-right: 14px; */
    font-size: 0.813rem; }

@media only screen and (max-width: 900px) {
  .image-container {
    display: none; }
  .container-fluid {
    grid-template-columns: 1fr; }
  .login-container {
    display: block;
    align-items: inherit;
    justify-content: inherit;
    flex-direction: inherit; }
  .logo-on-mobile-wrap {
    display: inherit; }
  .login-wrap {
    position: relative;
    top: 232px; }
  form {
    padding: 22px 28px 32px 28px; }
    form .welcome {
      position: relative;
      top: inherit;
      left: inherit;
      margin-bottom: 24px;
      text-align: center;
      width: 100%; }
      form .welcome h3 {
        font-size: 18px; }
    form .form-blur {
      top: 30px; }
  .i18n {
    right: inherit;
    top: 0px;
    padding: 6px;
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    z-index: 2; } }

@media only screen and (max-width: 425px) {
  .welcome {
    width: calc(100vw - calc(20px * 2)); }
  form {
    width: calc(100vw - calc(20px * 2)); }
    form .form-blur {
      width: calc(100vw - calc(20px * 2)); }
    form .bg {
      width: calc(100vw - calc(20px * 2)); }
  .group-form-footer {
    width: calc(100vw - calc(20px * 2)); }
  /*   
  .i18n {
    right: inherit;
  } */ }

@media only screen and (max-width: 375px) {
  .welcome {
    width: calc(100vw - calc(24px * 2)); }
  form {
    width: calc(100vw - calc(24px * 2)); }
    form .form-blur {
      width: calc(100vw - calc(24px * 2)); }
    form .bg {
      width: calc(100vw - calc(24px * 2)); }
  .group-form-footer {
    width: calc(100vw - calc(24px * 2)); } }

@media only screen and (max-width: 320px) {
  /*   
  form {
    padding: 28px;
  } */ }

@media only screen and (max-width: 280px) {
  button.get-in.google {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 12px 18px;
    text-align: left; }
    button.get-in.google img {
      margin-right: 14px; } }

@media only screen and (max-height: 640px) {
  /* .i18n {
    top: \$margin-layer;
  } */
  .login-container {
    min-height: 698px; }
  .login-wrap {
    padding-bottom: 60px; } }

@media only screen and (max-height: 768px) and (min-width: 900px) {
  .login-wrap {
    padding-top: 140px;
    padding-bottom: 60px; } }

@media only screen and (max-height: 568px) {
  /* .i18n {
    top: \$margin-layer;
  } */
  /* 
  .login-wrap {
    margin-top: 80px
  } */ }

.fs8 {
  font-size: 8px;
  line-height: 12px; }

.fs12 {
  font-size: 12px;
  line-height: 15px; }

.fs13 {
  font-size: 13px;
  line-height: 15px; }

.fs14 {
  font-size: 14px;
  line-height: 18px; }

.left-3\\.5 {
  left: 0.875rem; }

.right-3\\.5 {
  right: 0.875rem; }

.w-5 {
  width: 1.25rem; }

.h-5 {
  height: 1.25rem; }

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem; }

.absolute {
  position: absolute; }

.relative {
  position: relative; }
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
