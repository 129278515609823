import { ptBr as mfa } from '@/views/Mfa/locales';

export default {
  "pt-br": {
    support: "Suporte",
    copyright: "© {currentYear} - ROIT BANK. Todos os direitos reservados",
    accessWithGoogle: "Acessar com o Google",
    orAccessWith: "Ou acesse com",
    enter: "Entrar",
    accessYourAccount: "Acesse sua conta",
    password: "Senha",
    incorrectUserPassword: "Usuário e/ou senha incorretos",
    attention: "Atenção",
    noOrigin: "Usuário sem aplicativo de origem",
    userNotFound: "Usuário não encontrado no IAM",
    sessionExpired: "Sessão expirada, por favor faça o login novamente",
    welcome: "Seja bem-vindo (a)",
    keepMeSignedIn: "Manter conectado",
    forgotPassword: "Esqueci a senha",
    doNotHaveAnAccount: "Não possui uma conta?",
    createYoursHere: "Crie à sua aqui",
    privacy: "Políticas de Privacidade",
    termsOfUse: "Termos de Uso",
    forgotMyPassword: "Esqueci Minha Senha",
    forgotYourPassword: "Esqueceu sua senha?",
    forgotMyPasswordSubmit: "Redefinir Senha",
    forgotMyPasswordInfo: "Para redefinir sua senha, informe seu E-mail e lhe enviaremos um link com as instruções.",
    forgotMyPasswordEmailInfo: "Enviamos um e-mail contendo um link para redefinição da sua senha.",
    returnToLogin: "Voltar para login",
    accessWithMicrosoft: "Acessar com o Microsoft",
    accessWithSso: "Acessar com SSO",
    accountNotFound: "Usuário não localizado",
    accountNotFoundMsn: "Verifique se o email utilizado é o mesmo cadastrado em sua conta",
    invalidPassword: "Senha inválida",
    undefinedError: "Erro indefinido",
    tooManyRequests: "Muitas tentativas em um curto espaço de tempo, tente novamente mais tarde",
    userAccessPermissionOnlySso: "Usuário com permissão de acesso apenas utilizando SSO",
    invalidToken: "Token expirado ou inválido",
    invalidEmail: "E-mail inválido",
    analyticsAlert: "Por favor, contate o suporte!",
    warning: "Atenção!",
    unexpectedError: "Ocorreu um erro ao enviar o email, tente novamente mais tarde",
    wait: "Aguarde",
    emailResend: "segundos para reenviar",
    email: "E-mail",
    loginSuccess: "Login realizado com sucesso!",
    serverConnectionFailed: "Falha em comunicação com o servidor",
    needHelp: "Precisa de ajuda?",
    newVersion: "Após o login, você será redirecionado para a nova versão do sistema.",
    accessWith: "ou acesso com",
    backToLogin: 'Voltar à página de entrada',
    undefinedProvider: "Erro ao realizar a validação, entre em contato com a empresa.",
    ssoTitle: "SSO",
    ssoDescription: "Se a sua empresa permite, insira o nome no campo abaixo para realizar o acesso.",
    sso: {
      notify: {
        userFederationOnSync: {
          title: "Estamos configurando seu acesso",
          message: "Isso pode levar alguns minutos. Tente acessar novamente em 5 minutos."
        },
        cloudFunctionDeadlineExceeded: {
          title: "Tempo excedido",
          message: "Tempo excedido para realizar o acesso, tente novamente."
        },
        invalidTenant: {
          title: "Empresa inválida",
          message: "Empresa inválida, contacte o suporte."
        },
      }
    },
    ...mfa
  },
};
